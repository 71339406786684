<template>
  <div>
    <h5
      class="
        is-uppercase
        has-text-weight-medium has-text-centered has-text-primary
        p-2
        mb-2
        has-background-light
      "
    >
      Urls
    </h5>

    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit" ref="_form">
        <div class="columns">
          <div class="column is-8">
            <ValidationProvider
              name="url_name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field :type="errors[0] ? 'is-danger' : ''" expanded>
                <b-input v-model="url.name" placeholder="Nome"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column">
            <b-button native-type="submit" type="is-primary">Enviar</b-button>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <!--
    <b-field label="Nome..." :label-position="'on-border'">
      <b-autocomplete
        rounded
        :data="['jQuery', 'Vue', 'React']"
        placeholder="e.g. jQuery"
        icon="magnify"
        clearable
        @select="(option) => (selected = option)"
      >
        <template #empty>No results found</template>
      </b-autocomplete>
    </b-field>-->

    <b-table
      @click="setUrl"
      :row-class="(row, index) => row._id === url._id && 'is-info'"
      :data="urls"
      :columns="columns"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      focusable
    >
    </b-table>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      selected: {},
      url: {},
      urls: [],
      total: 0,
      perPage: 15,
      page: 1,
      columns: [
        {
          field: "name",
          label: "nome",
        },
        {
          field: "totalRecords",
          label: "Registros",
        },
      ],
    };
  },
  mounted() {
    this.loadAsyncData();
  },
  methods: {
    onFilterChange() {
      this.page = 1;
      this.loadAsyncData();
    },
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },

    loadAsyncData() {
      //active/:perPage/:page/:suspended
      const params = [this.perPage, this.page].join("/");
      this.$http
        .get(`${process.env.VUE_APP_URI}_url/${params}`)
        .then((response) => {
          this.total = response.data.total;
          this.urls = response.data.data;
        });
    },
    onSubmit() {
      const self = this;

      self.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        if (!self.url._id)
          self.$http
            .post(`${process.env.VUE_APP_URI}_url`, self.url)
            .then((response) => {
              self.$set(self.url, "_id", response.data._id);
              self.urls.push(self.url);
              self.$refs._form.reset();
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-success",
              });
            });

        if (self.url._id)
          self.$http
            .put(`${process.env.VUE_APP_URI}_url/${self.url._id}`, self.url)
            .then(() => {
              self.urls = self.urls.map(function (v) {
                if (v._id == self.url._id) v = self.url;
                return v;
              });
              self.$refs._form.reset();
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-info",
              });
            });
      });
    },
    setUrl(v) {
      this.url = JSON.parse(JSON.stringify(v));
    },
  },
};
</script>
<style>
.control {
  width: 80% !important;
}
tr.is-info {
  background: #167df0;
  color: #fff;
}
</style>